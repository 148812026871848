.custom-mdx-editor {
  border: 1px solid #bdbdbd;
  position: relative;
  border-radius: 5px;
}
/* Display: none is to avoid showing the placeholder container that is shown by default by the component itself */
.custom-mdx-editor + .custom-mdx-editor {
  display: none;
}

blockquote {
  border-left: 0.25em solid #e5e7eb;
  font-weight: 500;
  font-style: italic;
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1.3em;
}

code {
  background-color: hsl(210 16.7% 97.6%);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  color: hsl(322 75% 46%);
}

code.span {
  background-color: hsl(209, 13.3%, 95.3%);
  padding: 1px 0.25rem;
  font-size: 94%;
}
